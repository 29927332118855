import {
  DELETE_FLIGHT,
  DELETE_CAR,
  DELETE_BUS,
  DELETE_TRAIN,
  DELETE_FOOD,
  DELETE_DIET,
  DELETE_HOTEL,

  LOAD_TRIP,

  SUBMIT_FLIGHT,
  SUBMIT_FOOD,
  SUBMIT_DIET,
  SUBMIT_CAR,
  SUBMIT_BUS,
  SUBMIT_TRAIN,
  SUBMIT_HOTEL,

  RESET

} from "../actions/types/carbonItems"

const initialState = {
  tripId:'',
  flightList:[],
  carList:[],
  busList:[],
  trainList:[],
  foodList:[],
  dietList:[],
  hotelList:[],
}


const carbonItemsReducer = ( state = initialState, action)=>{

  switch (action.type){

    // Loading all lists + ID from a saved trip
    case LOAD_TRIP:
      return{
        ...state,
        flightList:action.payload.flightList,
        carList:action.payload.carList,
        busList:action.payload.busList,
        trainList:action.payload.trainList,
        foodList:action.payload.foodList,
        dietList:action.payload.dietList,
        hotelList:action.payload.hotelList,
        tripId:action.payload._id
      };

      case SUBMIT_FLIGHT:
        return{
          ...state,
          flightList:[...state.flightList, action.payload]
        };
      case SUBMIT_CAR:
        return{
          ...state,
          carList:[...state.carList, action.payload]
        };
      case SUBMIT_BUS:
        return{
          ...state,
          busList:[...state.busList, action.payload]
        };
      case SUBMIT_TRAIN:
        return{
          ...state,
          trainList:[...state.trainList, action.payload]
        };
      case SUBMIT_FOOD:
        return{
          ...state,
          foodList:[...state.foodList, action.payload]
        };
      case SUBMIT_DIET:
        return{
          ...state,
          dietList:[...state.dietList, action.payload]
        };
      case SUBMIT_HOTEL:
        return{
          ...state,
          hotelList:[...state.hotelList, action.payload]
        };
      case DELETE_FLIGHT:
        return{
          ...state,
          flightList:[...state.flightList.slice(0, action.payload),
                      ...state.flightList.slice(action.payload+1)]
        }
      case DELETE_CAR:
        return{
          ...state,
          carList:[...state.carList.slice(0, action.payload),
                      ...state.carList.slice(action.payload+1)]
        }
      case DELETE_BUS:
        return{
          ...state,
          busList:[...state.busList.slice(0, action.payload),
                      ...state.busList.slice(action.payload+1)]
        }
      case DELETE_TRAIN:
        return{
          ...state,
          trainList:[...state.trainList.slice(0, action.payload),
                      ...state.trainList.slice(action.payload+1)]
        }
      case DELETE_FOOD:
        return{
          ...state,
          foodList:[...state.foodList.slice(0, action.payload),
                      ...state.foodList.slice(action.payload+1)]
        }
      case DELETE_DIET:
        return{
          ...state,
          dietList:[...state.dietList.slice(0, action.payload),
                      ...state.dietList.slice(action.payload+1)]
        }
      case DELETE_HOTEL:
        return{
          ...state,
          hotelList:[...state.hotelList.slice(0, action.payload),
                      ...state.hotelList.slice(action.payload+1)]
        }
      case RESET:
        return{
          ...state,
          flightList:[],
          carList:[],
          busList:[],
          trainList:[],
          foodList:[],
          dietList:[],
          hotelList:[]
        }

      default:
        return state;
    }
}

export default carbonItemsReducer
