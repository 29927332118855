import {LOAD_USER} from '../actions/types/users';

const initialState = {
  user:{}
}

const userReducer =( state= initialState, action)=>{
  switch (action.type){
    case LOAD_USER:

      return{
        ...state,
        user:action.payload
      };
      default:
        return state;
    }
}

export default userReducer;
