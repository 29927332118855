import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {USER_LOADING,SET_CURRENT_USER} from '../types/auth';


export const loadUserName = (setCommentName)  =>{
  axios.get("/api/users/read/fetch-username").then(response => {
    setCommentName(response.data.name)
  })
};

// Register User
export const registerUser = (userData,page) => dispatch => {
  axios.post("api/users/create/register", userData).then(res => {
    // Set token to localStorage
    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = jwt_decode(token);
    // Set current user
    dispatch(setCurrentUser(decoded));
  })
    .catch(err =>

      { let errors=page.state.errors
        errors.registerEmailTaken=err.response.data.emailTaken
        page.setState({errors:errors,showLoadScreen:false})}

    );
};
// Login - get user token
export const loginUser = (userData,page) => dispatch => {

  axios.post("/api/users/update/login", userData)
    .then(res => {
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      { let errors=page.state.errors;
        if (err.response.data.emailnotfound) {
          errors.emailnotfound=err.response.data.emailnotfound;
        }
        if (err.response.data.passwordincorrect) {
          errors.passwordincorrect=err.response.data.passwordincorrect;
        }
        if (err.response.data.userInactive) {
          errors.userInactive=err.response.data.userInactive
        }
        page.setState({errors:errors})
      }

    );
};

// calls the deactivate account api and temporarily deactivates the current user
export const deactivateAccount = (userData, page) => dispatch => {
  axios.post("/api/users/update/deactivate", userData).then(res => {
  })
}

// Calls the reactivate account API and tries to reset the requested account
export const reactivateAccount = (userData,page) => dispatch => {
  axios.post("/api/users/update/reactivate", userData)
    .then(res => {

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));

    })
    .catch(err =>
      { //setting the error responses into the component state
        page.setState({emailError:err.response.data.email,
                       passwordError:err.response.data.password,
                       emailnotfound:err.response.data.emailnotfound,
                       passwordincorrect:err.response.data.passwordincorrect,
                       userInactive:err.response.data.userInactive})
      }
    );
};

// Calls the password reset api
//  makes the server generate a reset link
// Saves a token to the requested user to authenticate the generated link
// and sends the link to the user.
export const passwordReset = (userData,page) => dispatch => {
  axios.post("/api/passwordReset/passwordReset", userData)
    .then(res => {
    })
    .catch(err =>
      {

      }
    );
};

// Calls the reset password reset api
// updates the users password
export const resetPassword = (userData,page) => dispatch => {
  axios.post("/api/passwordReset/reset-password", userData).then(res => {
  }).catch(err =>
    { // Saving error messages to the components state
      page.setState({emailError:err.response.data.email,
                     passwordError:err.response.data.passwordError,
                     emailnotfound:err.response.data.emailnotfound,
                     passwordincorrect:err.response.data.passwordincorrect,
                     userInactive:err.response.data.userInactive})
    }
  );
}
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};
// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
