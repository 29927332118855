import {
  SUBMIT_COMMENT,
  SUBMIT_REPLY,
  LOAD_COMMENTS,
  DELETE_COMMENT,
  DELETE_REPLY
} from "../actions/types/comments"

const initialState = {
  comments:[]
}


const commentsReducer = ( state = initialState, action)=>{
  switch (action.type){
    case LOAD_COMMENTS:
      return{
        ...state,
        comments:action.payload
      }
    case SUBMIT_COMMENT:
      return{
        ...state,
        comments:[action.payload,...state.comments]
      }
    case SUBMIT_REPLY:
      return{
        ...state,
        comments:action.payload
      }
    case DELETE_COMMENT:
      return{
        ...state,
        comments:action.payload
      }
    case DELETE_REPLY:
      return{
        ...state,
        comments:action.payload
      }

    default:
      return state;
  }
}

export default commentsReducer
