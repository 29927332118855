import {TOGGLE_SUCESS} from '../actions/types/notifications';

const initialState = {
  show:false,
  message:'',
  type:'positive'
}

const notificationsReducer =( state= initialState, action)=>{
  switch (action.type){
    case TOGGLE_SUCESS:
      return{
        ...state,
        show:action.payload.show,
        message:action.payload.message,
        type:action.payload.type
      };

      default:
        return state;
    }
}

export default notificationsReducer;
