import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import authReducer from './reducers/auth'
import navigationReducer from './reducers/navigation'
import carbonItemsReducer from "./reducers/carbonItems"
import tripsReducer from "./reducers/trips"
import commentsReducer from "./reducers/comments"
import notificationsReducer from "./reducers/notifications"
import userReducer from "./reducers/users"
const initialState = {};

const rootReducer = combineReducers({
  navigation:navigationReducer,
  auth:authReducer,
  carbonItems:carbonItemsReducer,
  trips:tripsReducer,
  comments:commentsReducer,
  notifications:notificationsReducer,
  user:userReducer
})

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
);

export default store;
