import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";
import {  Row, Col, Container} from 'react-bootstrap';
import ReactGA from 'react-ga';
import store from './store/store';
import {Provider} from 'react-redux';
import  { useEffect } from 'react';
import coupleIcon from "./assets/icons/coupleIcon.png"
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./store/actions/creators/auth";
import ('./assets/styles/min.css');
// import ('./assets/styles/App.css');
// import ('./assets/styles/text.css');
// import ('./assets/styles/effects.css');
// import('./assets/styles/speechBubbles.css')
// import ('./assets/styles/borders.css');
// import ('./assets/styles/colours.css');
// import ('./assets/styles/elements.css');
// import ('./assets/styles/errors.css');
// import ('./assets/styles/form.css');
// import ('./assets/styles/images.css');
// import ('./assets/styles/layout.css');

const TRACKING_ID = "G-NPMRQ3YSSG"; // OUR_TRACKING_IDn

const Main = lazy(() => import('./components/main'));
const Login = lazy(() => import('./components/login'));
const CalculatorPage = lazy(() => import('./components/calculator'));
const AboutPage = lazy(() => import("./components/about"));
const BlogPage = lazy(() => import('./components/blogs'));
const GalleryPage = lazy(() => import('./components/galleries'));

const WhistlerFoodGuide = lazy(() => import('./components/blogs/whistlerFoodGuide'));
const WhistlerActivityGuide = lazy(() => import('./components/blogs/whistlerActivityGuide'));
const VancouverFoodGuide = lazy(() => import('./components/blogs/vancouverFoodGuide'));
const VancouverActivityGuide = lazy(() => import('./components/blogs/vancouverActivityGuide'));
const CarbonCostOfLatam = lazy(() => import('./components/blogs/carbonCostOfLatam'));
const CarbonCostOfFlying = lazy(() => import('./components/blogs/carbonCostOfFlying'));
const CarbonCostOfColombia = lazy(() => import('./components/blogs/carbonCostOfColombia'));
const CarbonCostOfEcuador = lazy(() => import('./components/blogs/carbonCostOfEcuador'));
const CarbonCostOfDiet = lazy(() => import('./components/blogs/carbonCostOfDiet'));
const BanosActivityGuide = lazy(() => import('./components/blogs/banosActivityGuide'));
const MedellinFoodGuide = lazy(() => import('./components/blogs/medellinFoodGuide'));
const InTheKnowQuito = lazy(() => import('./components/blogs/inTheKnowQuito'));
const InTheKnowGuatape = lazy(() => import('./components/blogs/inTheKnowGuatape'));
const InTheKnowMedellin = lazy(() => import('./components/blogs/inTheKnowMedellin'));
const InTheKnowArequipa = lazy(() => import('./components/blogs/inTheKnowArequipa'));
const InTheKnowHuacachina = lazy(() => import('./components/blogs/inTheKnowHuacachina'));
const InTheKnowCusco = lazy(() => import('./components/blogs/inTheKnowCusco'));
const PeruItinerary = lazy(() => import('./components/blogs/peruItinerary'));
const PeruVeganGuide = lazy(() => import('./components/blogs/peruVeganGuide'));
const WalesItinerary = lazy(() => import('./components/blogs/walesItinerary'));

const CostaRica = lazy(() => import('./components/galleries/costaRica'));
const Granada = lazy(() => import('./components/galleries/granada'));
const Ometepe = lazy(() => import('./components/galleries/ometepe'));
const Cartagena = lazy(() => import('./components/galleries/cartagena'));
const Guatape = lazy(() => import('./components/galleries/guatape'));
const Quindio = lazy(() => import('./components/galleries/quindio'));
const Medellin = lazy(() => import('./components/galleries/medellin'));
const Quito = lazy(() => import('./components/galleries/quito'));
const Otavalo = lazy(() => import('./components/galleries/otavalo'));
const Arequipa = lazy(() => import('./components/galleries/arequipa'));
const Cusco = lazy(() => import('./components/galleries/cusco'));
const Huacachina = lazy(() => import('./components/galleries/huacachina'));
const SacredValley = lazy(() => import('./components/galleries/sacredValley'));
const PanamaCity = lazy(() => import('./components/galleries/panamaCity'));
const Huaraz = lazy(() => import('./components/galleries/huaraz'));
const Wales = lazy(() => import('./components/galleries/wales'));
const Croatia = lazy(()=>import ('./components/galleries/croatia'))
const Greece = lazy(()=>import ('./components/galleries/greece'))
const AustriaSlovenia = lazy(()=>import ('./components/galleries/austria_slovenia'))
const ScrollToTop = lazy(() => import('./components/helpers/scrollToTop'));
const TermsAndConditions =lazy(()=>import('./components/standardComponents/termsAndConditions'))
ReactGA.initialize(TRACKING_ID);


// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
// Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}


function App() {

  useEffect(() => {
      ReactGA.pageview(window.location.pathname);
    }, []);
  return (
    <>
      <Provider store ={store}>
        <Router>
          <Suspense fallback={
            <Container className ='roaming-white full-width' style ={{height:'100vh'}}>
              <Row style ={{height:'33vh'}}>
                &nbsp;
              </Row>
              <Row >
                <Col className ='centered-children '>
                  <img style ={{maxWidth:'30vw'}}src ={coupleIcon} alt ='loadingIcon' />
                </Col>
              </Row>
              <Row className ='centered-children'>
                <Col className ='centered-children '>
                  <p className ='roaming-text centered-text'>One moment please...</p>
                </Col>
              </Row>
            </Container>
          }>


            <ScrollToTop>
            <Routes>

              <Route exact path="/" element={<Main/>} />
              <Route exact path="/home" element={<Main/>} />
              <Route exact path="/login" element={<Login/>} />
              <Route exact path="/calculator" element={<CalculatorPage/>} />
              <Route exact path="/about" element={<AboutPage/>} />
              <Route exact path="/blog" element={<BlogPage/>} />
              <Route exact path="/blog/:searchTerm" element={<BlogPage/>} />
              <Route exact path="/gallery" element={<GalleryPage/>} />



              <Route exact path="/calculator/:tripId" element={<CalculatorPage/>} />
              <Route exact path="/home/:redirectView" element={<Main/>} />

              <Route exact path="/WhistlerFoodGuide" element={<WhistlerFoodGuide/>} />
              <Route exact path="/VancouverFoodGuide" element={<VancouverFoodGuide/>} />
              <Route exact path="/MedellinFoodGuide" element={<MedellinFoodGuide/>} />

              <Route exact path="/WhistlerActivityGuide" element={<WhistlerActivityGuide/>} />
              <Route exact path="/VancouverActivityGuide" element={<VancouverActivityGuide/>} />
              <Route exact path="/BanosActivityGuide" element={<BanosActivityGuide/>} />

              <Route exact path="/CarbonCostOfLatam" element={<CarbonCostOfLatam/>} />
              <Route exact path="/CarbonCostOfColombia" element={<CarbonCostOfColombia/>} />
              <Route exact path="/CarbonCostOfDiet" element={<CarbonCostOfDiet/>} />
              <Route exact path="/CarbonCostOfFlying" element={<CarbonCostOfFlying/>} />
              <Route exact path="/CarbonCostOfEcuador" element={<CarbonCostOfEcuador/>} />

              <Route exact path="/InTheKnowMedellin" element={<InTheKnowMedellin/>} />
              <Route exact path="/InTheKnowQuito" element={<InTheKnowQuito/>} />
              <Route exact path="/InTheKnowGuatape" element={<InTheKnowGuatape/>} />

              <Route exact path="/InTheKnowArequipa" element={<InTheKnowArequipa/>} />
              <Route exact path="/InTheKnowHuacachina" element={<InTheKnowHuacachina/>} />
              <Route exact path="/InTheKnowCusco" element={<InTheKnowCusco/>} />
              <Route exact path="/PeruItinerary" element={<PeruItinerary/>} />
              <Route exact path="/PeruVeganGuide" element={<PeruVeganGuide/>} />
              <Route exact path="/WalesItinerary" element={<WalesItinerary/>} />

              <Route exact path="/CostaRica" element={<CostaRica/>} />
              <Route exact path="/Granada" element={<Granada/>} />
              <Route exact path="/Ometepe" element={<Ometepe/>} />
              <Route exact path="/Cartagena" element={<Cartagena/>} />
              <Route exact path="/Quindio" element={<Quindio/>} />
              <Route exact path="/Guatape" element={<Guatape/>} />
              <Route exact path="/Medellin" element={<Medellin/>} />
              <Route exact path="/Quito" element={<Quito/>} />
              <Route exact path="/Otavalo" element={<Otavalo/>} />
              <Route exact path="/Arequipa" element={<Arequipa/>} />

              <Route exact path="/Cusco" element={<Cusco/>} />
              <Route exact path="/Huacachina" element={<Huacachina/>} />
              <Route exact path="/SacredValley" element={<SacredValley/>} />
              <Route exact path="/PanamaCity" element={<PanamaCity/>} />
              <Route exact path="/Huaraz" element={<Huaraz/>}/>
              <Route exact path="/Wales" element={<Wales/>}/>
              <Route exact path='/Croatia' element ={<Croatia/>}/>
              <Route exact path='/Greece' element ={<Greece/>}/>
              <Route exact path='/Austria&Slovenia' element ={<AustriaSlovenia/>}/>
              <Route exact path="/TermsAndConditions"element={<TermsAndConditions/>}/>
            </Routes>
            </ScrollToTop>
          </Suspense>
        </Router>
      </Provider>

    </>
  );
}

export default App;
