export const LOAD_TRIP = 'LOAD_TRIP';

export const SUBMIT_FLIGHT = 'SUBMIT_FLIGHT'
export const SUBMIT_FOOD = 'SUBMIT_FOOD'
export const SUBMIT_CAR = 'SUBMIT_CAR'
export const SUBMIT_BUS = 'SUBMIT_BUS'
export const SUBMIT_TRAIN = 'SUBMIT_TRAIN'
export const SUBMIT_HOTEL = 'SUBMIT_HOTEL'
export const SUBMIT_DIET = 'SUBMIT_DIET'

export const DELETE_FLIGHT = 'DELETE_FLIGHT'
export const DELETE_FOOD = 'DELETE_FOOD'
export const DELETE_CAR = 'DELETE_CAR'
export const DELETE_BUS = 'DELETE_BUS'
export const DELETE_TRAIN = 'DELETE_TRAIN'
export const DELETE_HOTEL = 'DELETE_HOTEL'
export const DELETE_DIET = 'DELETE_DIET'

export const RESET = 'RESET'
