import {
  LOAD_ALL_TRIPS,SUBMIT_TRIP,SUBMIT_TRIP_CUSTOM_PIC,DELETE_TRIP
} from "../actions/types/trips";

const initialState = {
  trips:[]
}

const tripsReducer = ( state = initialState, action)=>{
  switch (action.type){

    case LOAD_ALL_TRIPS:
      return{
        ...state,
        trips:action.payload.trips
      };
    case SUBMIT_TRIP:
      return{
        ...state,
        trips:action.payload
      };

    case SUBMIT_TRIP_CUSTOM_PIC:
      return{
        ...state,
        trips:action.payload
      };
      case DELETE_TRIP:
        return{
          ...state,
          trips:action.payload
        }

  default:
    return state;
  }
}
export default tripsReducer;
